import React, { FC } from 'react'

import {
  FlattenedEntry,
  ContentSection as ContentSectionType,
} from '@viewlio/types/src/contentful'
import { ContentPosition, ContentWidth } from '@viewlio/types/src/contentful/ContentSection'

import { EntryContent } from 'components/contentful/EntryContent/EntryContent'
import { EntryLayout } from 'components/contentful/EntryLayout/EntryLayout'
import { MarkdownOptions } from 'utils/markdown'

import styles from './ContentSection.module.scss'

export type Props = FlattenedEntry<ContentSectionType> & {
  classNames? : {
    wrapperClassName: string
  }
  markdownOptions?: MarkdownOptions
}

export const ContentSection: FC<Props> = ({
  contentPosition = ContentPosition.Start,
  contentWidth = ContentWidth.Short,
  ...props
}) => (
  <EntryLayout
    contentPosition={contentPosition}
    contentWidth={contentWidth}
    {...props}
    renderEntry={(_gridChild) => (
      <div>
        <EntryContent
          contentPosition={contentPosition}
          headingClassName={styles.heading}
          {...props}
        />
      </div>
    )}
  />
)
